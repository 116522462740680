import {
  CREATE_COMPANY,
  COMPANY_SUCCESS,
  COMPANY_ERROR,
  UPDATE_COMPANY
} from "./actionTypes"

const INIT_STATE = {
  company: null,
  success: false,
  error: false
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_COMPANY:
      return {
        ...state,
        company: action.payload,
      }
    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload
      }
    case COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        success: action. payload ? 'Success!' : null
      }
    default:
      return state
  }
}

export default Company;
