import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import avatar from "../../assets/images/companies/img-4.png"
// actions
import { profileSuccess, getProfile } from '../../store/auth/profile/actions';
import Table from './Table';
import Loader from "../../components/Loader";
import Single from './Single';

const Hotels = props => {
  const { user } = props;
  const [isTable, setIsTable] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("bank");
  const [singleId, setSingleId] = useState(null);
  // console.log('props on company', props);

  useEffect(() => {
    if (props.location) {
      console.log('path on opportunities', props.location.pathname.split("/"));
      const path = props.location.pathname;
      if (path.split("/").length > 1) {
        const id = path.split("/")[2];
        if (id) {
          setSingleId(id);
          setIsTable(false);
        }
      } else {
        setIsTable(true);
      }
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Affiliation" />
        <Card>
          <CardBody>
            {isTable ?
              <Table
                role={"Affiliation"}
                path={path}
                user={props?.user}
                history={props.history}
                setLoading={setLoading}
                setIsTable={setIsTable}
                setSingleId={setSingleId}
              />
              :
              <Single
                singleId={singleId}
                user={props?.user}
                setIsTable={setIsTable}
                setLoading={setLoading}
              />
            }
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

Hotels.propTypes = {
  getProfile: PropTypes.func,
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, { getProfile })(Hotels)
)
