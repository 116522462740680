import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import AddUserForm from '../Forms/AddUserForm';
import Loader from "../../components/Loader";
import Enquiry from "./Enquiry";
import Subscriber from "./Subscriber";
import Booking from "./Booking";

const Agent = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  // console.log('props on company', props);

  useEffect(() => {
    if (props.location) {
      console.log('path on opportunities', props.location.pathname.split("/"));
      const path = props.location.pathname;
      if (path.split("/").length > 1) {
        const role = path.split("/")[2];
        if (role) {
          setPath(role);
        }
      }
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Users" />
        <Card>
          <CardBody>
            {path == 'enquiry' &&
              <Enquiry
                role={"Users"}
                path={path}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentUser}
                setCurrentData={setCurrentUser}
                getProfile={props?.getProfile}
                updateCompany={props?.updateCompany}
                history={props.history}
              />
            }
            {path == 'subscriber' &&
              <Subscriber
                role={"Users"}
                path={path}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentUser}
                setCurrentData={setCurrentUser}
                getProfile={props?.getProfile}
                updateCompany={props?.updateCompany}
                history={props.history}
              />
            }
            {path == 'booking' &&
              <Booking
                role={"Users"}
                path={path}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentUser}
                setCurrentData={setCurrentUser}
                getProfile={props?.getProfile}
                updateCompany={props?.updateCompany}
                history={props.history}
              />
            }
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Agent)
)
