import React from "react"
import { Container, Row, Col } from "reactstrap";
import Utils from '../../pages/Utility';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © {Utils.projectName}.</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Developed <i className="mdi mdi-heart text-danger"></i> by Team Rajmith
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
