import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const Adddestination = props => {
    const { user, data, setLoading, close } = props;
    const [file, setFile] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const imageInput = useRef();
    const imageInputMore = useRef();
    const cropper = useRef();
    const [meta, setMeta] = useState(null);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedExp, setSelectedExp] = useState([]);
    // const [selectedInsp, setSelectedInsp] = useState([]);
    const [selectedWho, setSelectedWho] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [moreImageModel, setMoreImageModel] = useState(false);
    const [moreFile, setMoreFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState('more');
    const [currentData, setCurrentData] = useState(null);
    const [tags, setTags] = useState([{ descContent: null, desc: '', pos: 1 }]);
    const [faq, setFaq] = useState([{}]);
    const [currentTag, setCurrentTag] = useState(0);
    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        console.log('values from form', values, tags, faq);
        if (moreFile?.length < 1) {
            toast.error('Select atleast 1 Banner image!');
            return;
        }
        if (desc == "") {
            toast.error(`Please enter the Destination description!`);
            return;
        }
        let found = tags?.findIndex(x => !x?.image || x?.image == null || x?.image == undefined);
        if (found != -1) {
            toast.error(`You might have not select the Tag${found + 1} image!`);
            return;
        }
        let found2 = tags?.findIndex(x => x?.desc == '');
        if (found2 != -1) {
            toast.error(`You might have not enter the Tag${found2 + 1} Description!`);
            return;
        }
        let tag = [], images = [];
        const promise1 = moreFile?.map(async (item) => {
            console.log('more images', item?.length);
            if (item?.length > 85) {
                const file = await urltoFile(item, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(file);
                const form = new FormData();
                form.append("image", temp);
                const image = await upload("/destination/image_upload", form);
                images.push(image?.data);
            } else {
                images.push(item);
            }
        })
        const promise2 = tags?.map(async (item) => {
            let img = "";
            if (item?.image?.length > 85) {
                const file = await urltoFile(item?.image, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(file);
                const form = new FormData();
                form.append("image", temp);
                const image = await upload("/destination/image_upload", form);
                img = image?.data;
            } else
                img = item?.image;
            tag.push({ tagName: item?.tagName, desc: item?.desc, image: img, pos: item?.pos });
        })
        await Promise.all(promise1);
        await Promise.all(promise2);
        let body = {
            name: values?.name,
            imageTitle: values?.imageTitle,
            desc: desc,
            tag,
            faq,
            images,
            pageTitle: values?.pageTitle,
            pageDesc: values?.pageDesc,
            pageKey: values?.pageKey,
            token: user?.token
        }
        console.log('body to submit', body);
        setLoading(true);
        if (data) {
            body = { ...body, destinationId: data?._id };
            let url = "/destination/update";
            console.log('body after upload images', body);
            put(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('destination added');
                        toast.success(json?.message);
                        props.close(false);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        } else {
            let url = "/destination/add";
            console.log('body after upload images', body);
            post(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('destination added');
                        toast.success(json?.message);
                        close(false);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.desc) {
                const contentBlock = htmlToDraft(data?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.terms);
                }
            }
            if (data?.images?.length > 0)
                setMoreFiles(data?.images)
            if (data?.tag?.length > 0) {
                data?.tag?.map((item) => {
                    const contentBlock = htmlToDraft(item?.desc);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        item.descContent = editorState;
                    }
                })
                setTags(data?.tag);
            }
            if (data?.faq?.length > 0)
                setFaq(data?.faq);
        }
    }, [data]);

    useEffect(() => {
        get('/destination/meta')
            .then(res => {
                setMeta(res?.data);
            })
    }, [user]);

    const onChangeFile = async (e) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setImageChanged(true);
        setCropperModal(true);
    }

    const removeImage = (i) => {
        console.log('on remove image', i);
        console.log('currentFileType currentFileType', currentFileType);
        setFile(null);
        if (currentFileType == 'file')
            setFile(null);
        else {
            let data = Object.assign([], moreFile);
            data.splice(i, 1);
            setMoreFiles(data);
        }
        console.log(file);
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const onEditorStateChangeTag = (editorState, index) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let temp = Object.assign([], tags);
        temp[index].desc = html;
        temp[index].descContent = editorState;
        setTags(temp);
    }

    const cropImage = (e, v) => {
        console.log('currentFileType', currentFileType)
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();

        if (currentFileType == 'tags') {
            let temp = Object.assign([], tags);
            temp[currentTag].image = fileData;
            setTags(temp);
        } else {
            let data = Object.assign([], moreFile);
            data.push(fileData);
            setMoreFiles(data);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleMultiSelect = (val, type) => {
        if (val == "")
            return;
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedMonths(data);
            }
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedExp(data);
            }
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedWho(data);
            }
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedLocation(data);
            }
        }
    }

    const handleRemoveMulti = (index, type) => {
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            data.splice(index, 1);
            setSelectedMonths(data);
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            data.splice(index, 1);
            setSelectedExp(data);
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            data.splice(index, 1);
            setSelectedWho(data);
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            data.splice(index, 1);
            setSelectedLocation(data);
        }
    }

    const getName = (id, type) => {
        if (type == 'month') {
            let data = Object.assign([], meta?.months);
            let found = data.find(x => x._id == id);
            // console.log('found month obj', found);
            return found?.name;
        } else if (type == 'exp') {
            let data = Object.assign([], meta?.experiences);
            let found = data.find(x => x._id == id);
            // console.log('found exp obj', found);
            return found?.name;
        } else if (type == 'inspiration') {
            let data = Object.assign([], meta?.inspirations);
            let found = data.find(x => x._id == id);
            // console.log('found inspiration obj', found);
            return found?.name;
        } else if (type == 'who') {
            let data = Object.assign([], meta?.whos);
            let found = data.find(x => x._id == id);
            // console.log('found who obj', found);
            return found?.name;
        } else if (type == 'location') {
            let data = Object.assign([], meta?.locations);
            let found = data.find(x => x._id == id);
            // console.log('found location obj', found);
            return found?.name;
        } else {
            return "";
        }
    }

    const uploadMoreFiles = () => {
        setLoading(true);
        console.log('total files', moreFile.length);
        let banners = [];
        moreFile.map(async (item, index) => {
            await urltoFile(item, new Date().getTime() + '.png', 'image/png')
                .then(async (obj) => {
                    console.log('values', obj);
                    const image = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", image);
                    await upload("/destination/image_upload", form)
                        .then(res => {
                            console.log('response from image upload', res);
                            if (res?.statusCode == 200) {
                                banners.push(res?.data);
                                if (index == moreFile.length - 1) {
                                    let body = {
                                        destinationId: currentData?._id,
                                        banners,
                                        token: user?.token,
                                    }
                                    let url = "/destination/update";
                                    console.log('body after upload images', body);
                                    put(url, body)
                                        .then((json) => {
                                            console.log('response from adding comp', json);
                                            setLoading(false);
                                            if (json.statusCode == 200) {
                                                console.log('destination more image updated!');
                                                toast.success(json?.message);
                                                props.close(false);
                                            } else {
                                                toast.error(json?.error);
                                            }
                                        })
                                        .catch(error => {
                                            console.error('error while adding oppor', error);
                                            toast.error(JSON.stringify(error));
                                            setLoading(false);
                                        })
                                }
                            }
                        }).catch(error => {
                            console.error('error while uploading images', error);
                            toast.error(JSON.stringify(error));
                            setLoading(false);
                        })
                })
        })
    }

    const onTagTitleChange = (val, index) => {
        let temp = Object.assign([], tags);
        temp[index].tagName = val;
        setTags(temp);
    }

    const onTagPosChange = (val, index) => {
        let temp = Object.assign([], tags);
        temp[index].pos = val;
        setTags(temp);
    }

    const handleMoreFaq = () => {
        let temp = Object.assign([], faq);
        temp.push({});
        setFaq(temp);
    }

    const handleMoreTag = () => {
        let temp = Object.assign([], tags);
        temp.push({ faq: [{}] });
        setTags(temp);
    }

    const onChangeFaq = (child, to, val) => {
        let temp = Object.assign([], faq);
        temp[child][to] = val
        setFaq(temp);
    }

    const handleRemoveTag = (i) => {
        let temp = Object.assign([], tags);
        temp.splice(i, 1);
        setTags(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <input type="file" id="file"
                        ref={imageInputMore}
                        onChange={(e) => onChangeFile(e)}
                        style={{ display: "none" }} />
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row lg={12} md={12}>
                                    <div className="d-flex">
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Row lg={12} md={12}>
                                    {moreFile.length > 0 &&
                                        <>
                                            {moreFile.map((item, index) => (
                                                <Col lg={4}>
                                                    <div className="me-3 position-relative mt-4" style={{ width: 330 }}>
                                                        <img
                                                            src={item ? item : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: 'auto' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage(index)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    }
                                    {moreFile.length < 5 &&
                                        <Col lg={4}>
                                            <div className="me-3 position-relative mt-4" style={{ width: 330 }}>
                                                <img
                                                    src={avatar}
                                                    alt=""
                                                    style={{ width: '100%', height: 'auto' }}
                                                    onClick={(e) => { setCurrentFileType('more'); imageInputMore.current.click(e) }}
                                                />
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                    onClick={(e) => { setCurrentFileType('more'); imageInputMore.current.click(e) }}
                                                >
                                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                </div>

                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {/* <div className="align-self-center">
                                        <div className="text-muted">
                                            <h5>{companyName}</h5>
                                            <p className="mb-1">ID: {data?.compId ? data?.compId : 'AP****'}
                                                <Button onClick={(e) => { navigator.clipboard.writeText(data?.compId); setCopied(true) }}
                                                    title={"Copy"}
                                                    style={{ backgroundColor: Utils.themeColor, marginLeft: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
                                                {copied &&
                                                    <span style={{ color: 'green' }}>Copied!</span>
                                                }</p>
                                        </div>
                                    </div> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={currentFileType == 'more' ? 16 / 8 : 1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update Card Details' : 'Add a destination'}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="name"
                                            label="Title*"
                                            value={data?.name}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="imageTitle"
                                            label="Image Title*"
                                            value={data?.imageTitle}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                {/* terms */}
                                <div className="form-group mb-4">
                                    {/* How it works */}
                                    <label>Description*</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => onEditorStateChange(e)}
                                        editorState={descContent}
                                    />
                                </div>
                            </Row>
                            <h4 className="card-title mb-4">Tags</h4>
                            {tags?.map((item, index) => (
                                <div style={{ border: '1px dashed ' + Utils.themeColor, padding: 10, marginTop: 10 }}>
                                    <Row>
                                        <Col md={9}>
                                            <h4 className="card-title mb-4">Tag {index + 1}</h4>

                                        </Col>
                                        <Col md={3}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginRight: 10 }}>Position </span>
                                                <AvField
                                                    name={"pos" + index}
                                                    value={item?.pos}
                                                    type="select"
                                                    onChange={(e) => onTagPosChange(e.target.value, index)}
                                                >
                                                    <option value="">Select Position</option>
                                                    {tags?.map((item, ind) => (
                                                        <option>{ind + 1}</option>
                                                    ))}
                                                </AvField>
                                                {index > 0 &&
                                                    <div style={{ display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: Utils.themeColor, padding: '5px 9px', cursor: 'pointer' }}
                                                        onClick={() => handleRemoveTag(index)}
                                                    >
                                                        <i className='mdi mdi-delete' style={{ fontSize: 20, color: 'white' }} />
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"tagName" + index}
                                                    label="Tag Name*"
                                                    className="form-control"
                                                    type="text"
                                                    value={item?.tagName}
                                                    onChange={(e) => onTagTitleChange(e.target.value, index)}
                                                    required
                                                >
                                                </AvField>
                                            </div>
                                            {item?.image ?
                                                <>
                                                    <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                                                        <Col lg={4}>
                                                            <div className="me-3 position-relative mt-4" style={{ width: 280 }}>
                                                                <img
                                                                    src={item?.image}
                                                                    alt=""
                                                                    style={{ width: '100%', height: 'auto' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => removeImage(index)}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </>
                                                :
                                                <div className="me-3 position-relative mt-4" style={{ width: 280 }}>
                                                    <img
                                                        src={avatar}
                                                        alt=""
                                                        style={{ width: '100%', height: 'auto' }}
                                                        onClick={(e) => { setCurrentTag(index); setCurrentFileType('tags'); imageInputMore.current.click(e) }}
                                                    />
                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                        onClick={(e) => { setCurrentTag(index); setCurrentFileType('tags'); imageInputMore.current.click(e) }}
                                                    >
                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                    </div>

                                                </div>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group mb-4">
                                                {/* How it works */}
                                                <label>Description*</label>
                                                <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => onEditorStateChangeTag(e, index)}
                                                    editorState={item?.descContent}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <div className="d-flex mt-2">
                                <div className="text-center" style={{ marginLeft: 'auto' }}>
                                    <Button onClick={() => handleMoreTag()} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                        + Add More Tag
                                    </Button>
                                </div>
                            </div>
                            <h4 className="card-title mb-4">FAQ</h4>
                            {faq?.map((faq, ind) => (
                                <div style={{ border: '1px dashed ' + Utils.themeColor, padding: 10, marginTop: 10 }}>
                                    <h4 className="card-title mb-4">FAQ {ind + 1}</h4>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"question" + ind}
                                                    label="Question*"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                    value={faq?.question}
                                                    onChange={(e) => onChangeFaq(ind, 'question', e.target.value)}
                                                >
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"answer" + ind}
                                                    label="Answer*"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                    value={faq?.answer}
                                                    onChange={(e) => onChangeFaq(ind, 'answer', e.target.value)}
                                                >
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <div className="d-flex mt-2">
                                <div className="text-center" style={{ marginLeft: 'auto' }}>
                                    <Button color="primary" onClick={() => handleMoreFaq()} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                        + Add More FAQ
                                    </Button>
                                </div>
                            </div>
                            <h5 className="text-black font-size-20">On Page</h5>
                            <Row className="mt-4">
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageTitle"
                                            label="Page Title"
                                            className="form-control"
                                            placeholder="Enter page title"
                                            type="text"
                                            required
                                            value={data?.pageTitle}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageKey"
                                            label="Page Keywords"
                                            className="form-control"
                                            placeholder="Enter page keyword (keyword1, keyword2, ...)"
                                            type="textarea"
                                            required
                                            value={data?.pageKey}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageDesc"
                                            label="Page Description"
                                            className="form-control"
                                            placeholder="Enter page description"
                                            type="textarea"
                                            required
                                            value={data?.pageDesc}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update destination
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add destination
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

Adddestination.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(Adddestination)
)
