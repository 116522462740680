import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const AddTour = props => {
    const { user, data, setLoading, close } = props;
    const [file, setFile] = useState(null);
    const [highFile, setHighFile] = useState(null);
    const [banner, setBanner] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const bannerInput = useRef();
    const imageInput = useRef();
    const imageInput2 = useRef();
    const imageInputMore = useRef();
    const cropper = useRef();
    const [meta, setMeta] = useState(null);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [artistDescContent, setArtistDescContent] = useState(null);
    const [artistDesc, setArtistDesc] = useState("");
    const [highDescContent, setHighDescContent] = useState(null);
    const [highDesc, setHighDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [moreFile, setMoreFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState('more');
    const [tags, setTags] = useState([{ descContent: null, desc: '' }]);
    const [itin, setItin] = useState([{}]);
    const [currentTag, setCurrentTag] = useState(0);
    const [tourPoint, setTourPoint] = useState([{ about: '', condition: '' }]);
    const [travelDate, setTravelDate] = useState([""]);
    const [isHighLight, setIsHighLight] = useState(false);
    const [isMapPointer, setIsMapPointer] = useState(false);
    const [maps, setMaps] = useState([]);
    const [mapImage, setMapImage] = useState(null);
    const [currentLength, setCurrentLength] = useState(0);
    const [desti, setDesti] = useState([]);
    const [pdf, setPdf] = useState(null);
    const [isGroupTour, setIsGroupTour] = useState(false);
    const mapInput = useRef();
    // console.log('props on company', props?.data);


    useEffect(() => {
        get('/home/header/admin')
            .then((res => {
                if (res?.statusCode == 200) {
                    setDesti(res?.data?.desti);
                    setMeta(res?.data?.exp);
                }
            }));
    }, []);

    async function handleValidSubmit(event, values) {
        console.log('values from form', values, tags, itin);
        if (moreFile?.length < 1) {
            toast.error('Select atleast 1 Banner image!');
            return;
        }
        if (desc == "") {
            toast.error(`Please enter the Tour description!`);
            return;
        }
        if (!file) {
            toast.error(`Please select the artist image!`);
            return;
        }
        let found2 = itin?.findIndex(x => !x?.desc || x?.desc == '');
        if (found2 != -1) {
            toast.error(`You might have not enter the Itinerary Step${found2 + 1} Description!`);
            return;
        }
        let iti = [], images = [];
        const promise2 = itin?.map(async (item) => {
            iti.push({ title: item?.title, desc: item?.desc });
        })
        const promise1 = moreFile?.map(async (item) => {
            console.log('more images', item?.length);
            if (item?.length > 85) {
                const obj = await urltoFile(item, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const image = await upload("/tour/image_upload", form);
                images.push(image?.data);
            } else {
                images.push(item);
            }
        })
        await Promise.all(promise1);
        await Promise.all(promise2);
        let artistImage = "", highlightImage = "", bann = "", pdfUrl = "", mapImg = "";
        // console.log('banner to upload', banner)
        if (banner?.length > 85) {
            const obj = await urltoFile(banner, new Date().getTime() + '.png', 'image/png');
            const temp = await resizeFile(obj);
            const form = new FormData();
            form.append("image", temp);
            const image = await upload("/tour/image_upload", form);
            bann = image?.data;
        } else
            bann = banner;
        if (mapImage?.length > 85) {
            const obj = await urltoFile(mapImage, new Date().getTime() + '.png', 'image/png');
            const temp = await resizeFile(obj);
            const form = new FormData();
            form.append("image", temp);
            const image = await upload("/tour/image_upload", form);
            mapImg = image?.data;
        } else
            mapImg = mapImage;
        if (file?.length > 85) {
            const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
            const temp = await resizeFile(obj);
            const form = new FormData();
            form.append("image", temp);
            const image = await upload("/tour/image_upload", form);
            artistImage = image?.data;
        } else {
            artistImage = file;
        }
        if (highFile?.length > 85) {
            const obj = await urltoFile(highFile, new Date().getTime() + '.png', 'image/png');
            const temp = await resizeFile(obj);
            const form = new FormData();
            form.append("image", temp);
            const image = await upload("/tour/image_upload", form);
            highlightImage = image?.data;
        } else {
            highlightImage = highFile;
        }
        console.log('pdf to upload', pdf);
        if (pdf && typeof pdf == 'object') {
            const form = new FormData();
            form.append("image", pdf);
            const uploadedPdf = await upload("/tour/image_upload", form);
            console.log('uploaded pdf', uploadedPdf);
            pdfUrl = uploadedPdf.data;
        }
        let body = {
            name: values?.name,
            imageTitle: values?.imageTitle,
            amount: values?.amount,
            overview: values?.overview,
            desc: desc,
            itin: iti,
            pdf: pdfUrl,
            banner: bann,
            images,
            artistName: values?.artistName,
            artistImage,
            artistDesc,
            highlightDesc: highDesc,
            highlightImage: highlightImage,
            experienceId: values?.experienceId,
            destinationId: values?.destinationId,
            youtubeUrl: values?.youtubeUrl,
            travelDate: travelDate,
            tourPoint,
            mapImage: mapImg,
            pageTitle: values?.pageTitle,
            pageDesc: values?.pageDesc,
            pageKey: values?.pageKey,
            token: user?.token
        }
        if (!isHighLight) {
            body.highlightDesc = "";
            body.highlightImage = "";
        }
        console.log('body to submit', body);
        setLoading(true);
        if (data?._id) {
            body = { ...body, tourId: data?._id };
            let url = "/tour/update";
            console.log('body after upload images', body);
            put(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('tour added');
                        toast.success(json?.message);
                        props.close(false);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        } else {
            let url = "/tour/add";
            console.log('body after upload images', body);
            post(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('tour added');
                        toast.success(json?.message);
                        close(false);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.desc) {
                const contentBlock = htmlToDraft(data?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.desc);
                }
            }
            if (data?.artistDesc) {
                const contentBlock = htmlToDraft(data?.artistDesc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setArtistDescContent(editorState);
                    setArtistDesc(data?.artistDesc);
                }
            }
            if (data?.highlightDesc) {
                const contentBlock = htmlToDraft(data?.highlightDesc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setHighDescContent(editorState);
                    setHighDesc(data?.highlightDesc);
                    setIsHighLight(true);
                }
            }
            if (data?.banner?.length > 0)
                setBanner(data?.banner);
            if (data?.images?.length > 0)
                setMoreFiles(data?.images)
            if (data?.artistImage)
                setFile(data?.artistImage)
            if (data?.highlightImage)
                setHighFile(data?.highlightImage)
            if (data?.itin?.length > 0) {
                data?.itin?.map((item) => {
                    const contentBlock = htmlToDraft(item?.desc);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        item.descContent = editorState;
                    }
                })
                setItin(data?.itin);
            }
            if (data?.tourPoint?.length > 0)
                setTourPoint(data?.tourPoint);
            if (data?.travelDate?.length > 0)
                setTravelDate(data?.travelDate);
            if (data?.mapImage) {
                setMapImage(data?.mapImage);
                setIsMapPointer(true);
            }
            if (data?.pdf)
                setPdf(data?.pdf);
            if (data?.experienceId) {
                let temp = Object.assign([], meta);
                let find = temp.find(x => x._id == data?.experienceId);
                if (find?.name == 'Group Tour')
                    setIsGroupTour(true);
                else
                    setIsGroupTour(false);
            }
        }
    }, [data, meta]);

    const onChangeFile = async (e, to) => {
        console.log('on change file', e.target);
        setCurrentFileType(to);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setImageChanged(true);
        setCropperModal(true);
    }

    const removeImage = (i, type) => {
        console.log('on remove image', i);
        console.log('currentFileType currentFileType', currentFileType);
        if (currentFileType == 'file')
            setFile(null);
        else {
            let data = Object.assign([], moreFile);
            data.splice(i, 1);
            setMoreFiles(data);
        }
        console.log(file);
    }

    const onEditorStateChange = (editorState, to) => {
        if (to == 'artist') {
            const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setArtistDesc(html);
            setArtistDescContent(editorState);
        } else if (to == 'high') {
            const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setHighDesc(html);
            setHighDescContent(editorState);
        } else {
            const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setDesc(html);
            setDescContent(editorState);
        }
    }

    const onEditorStateChangeItin = (editorState, index) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let temp = Object.assign([], itin);
        temp[index].desc = html;
        temp[index].descContent = editorState;
        setItin(temp);
    }

    const cropImage = (e, v) => {
        console.log('currentFileType', currentFileType)
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentFileType == 'artist') {
            setFile(fileData);
        } else if (currentFileType == 'high') {
            setHighFile(fileData);
        } else if (currentFileType == 'banner') {
            setBanner(fileData);
        } else if (currentFileType == 'map') {
            setMapImage(fileData);
        } else {
            let data = Object.assign([], moreFile);
            data.push(fileData);
            setMoreFiles(data);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        // console.log('checking file============>', url);
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleMoreitin = () => {
        let temp = Object.assign([], itin);
        temp.push({});
        setItin(temp);
    }

    const onChangeItinTitle = (ind, val) => {
        let temp = Object.assign([], itin);
        temp[ind].title = val
        setItin(temp);
    }

    const handleRemoveIntin = (index) => {
        // console.log('removing itin', index, itin);
        let temp = Object.assign([], itin);
        temp.splice(index, 1);
        setItin(temp);
        // console.log('after remove itin', temp);
    }

    const handleRemovePoint = (index) => {
        let temp = Object.assign([], tourPoint);
        temp.splice(index, 1);
        setTourPoint(temp);
    }

    const handleMorePoint = () => {
        let temp = Object.assign([], tourPoint);
        temp.push({ about: '', condition: '' });
        setTourPoint(temp);
    }

    const handlePointsChange = (val, index, to) => {
        let temp = Object.assign([], tourPoint);
        if (to == 'about')
            temp[index].about = val;
        else
            temp[index].condition = val;
        setTourPoint(temp);
    }

    const handleChangeTravelDate = (val, index) => {
        let temp = Object.assign([], travelDate);
        temp[index] = val;
        setTravelDate(temp);
    }

    const handleMoreTravelDate = () => {
        let temp = Object.assign([], travelDate);
        temp.push("");
        setTravelDate(temp);
    }

    const handleRemoveTravel = (index) => {
        let temp = Object.assign([], travelDate);
        temp.splice(index, 1);
        setTravelDate(temp);
    }

    const handleMoreMap = () => {
        let temp = Object.assign([], maps);
        temp.push({ title: '', lat: '', long: '' });
        setMaps(temp);
    }

    const handleRemoveMap = (index) => {
        let temp = Object.assign([], maps);
        temp.splice(index, 1);
        setMaps(temp);
    }

    const onChangeMapCont = (index, val, to) => {
        let temp = Object.assign([], maps);
        temp[index][to] = val;
        setMaps(temp);
    }

    const handleDupliItin = () => {
        let temp = Object.assign([], itin);
        temp.push(temp[temp?.length - 1]);
        setItin(temp);
    }

    const handleExperienceChange = (val) => {
        let temp = Object.assign([], meta);
        let find = temp.find(x => x._id == val);
        if (find.name == 'Group Tour')
            setIsGroupTour(true);
        else
            setIsGroupTour(false);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <input type="file" id="file"
                        ref={imageInputMore}
                        onChange={(e) => onChangeFile(e, 'more')}
                        style={{ display: "none" }} />
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row lg={12} md={12}>
                                    <div className="d-flex mb-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h4 className='mb-0'>Banner</h4>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Row lg={12} md={12}>
                                    {banner ?
                                        <>
                                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer' }}>
                                                <div className="position-relative" style={{ width: 400, height: 285 }}>
                                                    <img
                                                        src={banner}
                                                        alt=""
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                        onClick={(e) => setBanner(null)}
                                                    >
                                                        <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="position-relative image_placeholder" style={{ width: 400, height: 285, cursor: 'pointer' }} onClick={(e) => bannerInput.current.click(e)}>
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            // onClick={(e) => bannerInput.current.click(e)}
                                            >
                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                            </div>

                                        </div>
                                    }
                                    <input type="file" id="file"
                                        ref={bannerInput}
                                        onChange={(e) => onChangeFile(e, 'banner')}
                                        style={{ display: "none" }} />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* more files */}
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row lg={12} md={12}>
                                    <div className="d-flex mb-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h4 className='mb-0'>Banners</h4>
                                        {/* <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div> */}
                                    </div>
                                </Row>
                                <Row lg={12} md={12}>
                                    {moreFile.length > 0 &&
                                        <>
                                            {moreFile.map((item, index) => (
                                                <Col lg={4} className='mb-4'>
                                                    <div className="position-relative" style={{ width: '100%' }}>
                                                        <img
                                                            src={item ? item : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: 140, borderRadius: 5 }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage(index)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    }
                                    {moreFile.length < 5 &&
                                        <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mb-4'>
                                            <div className="position-relative image_placeholder" style={{ width: '100%', height: 140, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                onClick={(e) => { setCurrentFileType('more'); imageInputMore.current.click(e) }}
                                            >
                                                <Button color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                    Choose Image
                                                </Button>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                // onClick={(e) => { setCurrentFileType('more'); imageInputMore.current.click(e) }}
                                                >
                                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={currentFileType == 'more' ? 16 / 5 : currentFileType == 'banner' ? 400 / 285 : 1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); if (currentFileType == 'file') setFile(null); setBanner(null); setHighFile(null); }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <h4 className="card-title mb-4">{data ? 'Update Tour' : 'Add a Tour'}</h4>
                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            {/* title and image title */}
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="name"
                                            label="Title*"
                                            value={data?.name}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="imageTitle"
                                            label="Image Title*"
                                            value={data?.imageTitle}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* image and youtube video */}
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="destinationId"
                                            label="Destination*"
                                            value={data?.destinationId}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value="">Select Destination</option>
                                            {desti?.map((item) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="experienceId"
                                            label="Experience*"
                                            value={data?.experienceId}
                                            className="form-control"
                                            type="select"
                                            required
                                            onChange={(e) => handleExperienceChange(e.target.value)}
                                        >
                                            <option value="">Select Experience</option>
                                            {meta?.map((item) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="youtubeUrl"
                                            label="Video Url*"
                                            value={data?.youtubeUrl}
                                            className="form-control"
                                            placeholder="Enter Youtube url i.e (https://www.youtube.com/watch?v=B8S2jhWwTDg)"
                                            type="url"
                                            required
                                        />
                                    </div>
                                </Col>
                                {isGroupTour &&
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="amount"
                                                label="Amount *"
                                                value={data?.amount}
                                                className="form-control"
                                                placeholder="Enter Amount i.e ($2345)"
                                                type="text"
                                                required
                                            />
                                        </div>
                                    </Col>
                                }
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <label>Short Overview *</label>
                                        <AvField
                                            type="textarea"
                                            name="overview"
                                            row={4}
                                            value={data?.overview}
                                            onChange={(e) => setCurrentLength(e.target?.value?.length)}
                                        // validate={{ maxLength: { value: 50 } }}
                                        />
                                        {/* <span style={{ float: 'right' }}>{currentLength} / 50</span> */}
                                    </div>
                                </Col>
                            </Row>
                            {isGroupTour &&
                                <Row>
                                    <Col md={6}>
                                        {travelDate?.map((item, index) => (
                                            <div className="form-group mb-4">
                                                {index > 0 ?
                                                    <div className='d-flex'>
                                                        <div style={{ width: '90%', marginRight: 10 }}>
                                                            <AvField
                                                                name={"travelDate" + index}
                                                                label="Travel Date*"
                                                                value={item}
                                                                className="form-control"
                                                                type="text"
                                                                onChange={(e) => handleChangeTravelDate(e.target.value, index)}
                                                                required
                                                            />
                                                        </div>
                                                        <div style={{ display: 'flex', height: 38, marginTop: 'auto', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: Utils.themeColor, padding: '5px 9px', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveTravel(index)}
                                                        >
                                                            <i className='mdi mdi-delete' style={{ fontSize: 20, color: 'white' }} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <AvField
                                                        name={"travelDate" + index}
                                                        label="Travel Date*"
                                                        value={item}
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => handleChangeTravelDate(e.target.value, index)}
                                                        required
                                                    />
                                                }
                                            </div>
                                        ))}
                                        <div className="d-flex mt-2">
                                            <div className="text-center" style={{ marginLeft: 'auto' }}>
                                                <Button color="primary" onClick={() => handleMoreTravelDate()} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                    + Add More Date
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <Label>Tour Cost points</Label>
                                            {tourPoint?.map((item, index) => (
                                                <div>
                                                    <Label>Point {index + 1}</Label>
                                                    <Row>
                                                        <Col md={6}>
                                                            <AvField
                                                                name={"about" + index}
                                                                value={item?.about}
                                                                className="form-control"
                                                                placeholder="Enter about"
                                                                type="text"
                                                                onChange={(e) => handlePointsChange(e.target.value, index, 'about')}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            {index > 0 ?
                                                                <div className='d-flex'>
                                                                    <div style={{ width: '90%', marginRight: 10 }}>
                                                                        <AvField
                                                                            name={"condition" + index}
                                                                            value={item?.condition}
                                                                            className="form-control"
                                                                            placeholder="Enter condition"
                                                                            onChange={(e) => handlePointsChange(e.target.value, index, 'condition')}
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: 'flex', height: 38, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: Utils.themeColor, padding: '5px 9px', cursor: 'pointer' }}
                                                                        onClick={() => handleRemovePoint(index)}
                                                                    >
                                                                        <i className='mdi mdi-delete' style={{ fontSize: 20, color: 'white' }} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <AvField
                                                                    name="condition"
                                                                    value={item?.condition}
                                                                    className="form-control"
                                                                    placeholder="Enter condition"
                                                                    type="text"
                                                                    onChange={(e) => handlePointsChange(e.target.value, index, 'condition')}
                                                                    required
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <div className="d-flex mt-2">
                                                <div className="text-center" style={{ marginLeft: 'auto' }}>
                                                    <Button color="primary" onClick={() => handleMorePoint()} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                        + Add More Point
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                {/* terms */}
                                <div className="form-group mb-4">
                                    {/* How it works */}
                                    <label>Description*</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => onEditorStateChange(e)}
                                        editorState={descContent}
                                    />
                                </div>
                            </Row>
                            {isGroupTour &&
                                <>
                                    <h4 className="card-title mb-4">Artist Profile</h4>
                                    <div style={{ border: '1px dashed ' + Utils.themeColor, padding: 10, marginTop: 10 }}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="form-group mb-4">
                                                    <AvField
                                                        name="artistName"
                                                        label="Name*"
                                                        value={data?.artistName}
                                                        className="form-control"
                                                        placeholder="Enter Title"
                                                        type="text"
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6} lg={6}></Col>
                                            <Col md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {file ?
                                                    <>
                                                        <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer' }}>
                                                            <div className="position-relative" style={{ width: 300, height: 300 }}>
                                                                <img
                                                                    src={file}
                                                                    alt=""
                                                                    style={{ width: '100%', height: 'auto' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => setFile(null)}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="position-relative image_placeholder" style={{ width: 300, height: 300 }}>
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>

                                                    </div>
                                                }
                                                <input type="file" id="file"
                                                    ref={imageInput}
                                                    onChange={(e) => onChangeFile(e, 'artist')}
                                                    style={{ display: "none" }} />
                                            </Col>
                                            <Col md={8}>
                                                <div className="form-group mb-4">
                                                    {/* How it works */}
                                                    <label>Description*</label>
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => onEditorStateChange(e, 'artist')}
                                                        editorState={artistDescContent}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            }
                            <h4 className="card-title mb-4 mt-4 d-flex justify-content-between align-items-center">
                                <span>Highlights</span><Button color="primary" onClick={() => setIsHighLight(!isHighLight)} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                    {isHighLight ? 'Delete Highlights' : 'Add Highlights'}
                                </Button>
                            </h4>
                            {isHighLight &&
                                <div style={{ border: '1px dashed ' + Utils.themeColor, padding: 10, marginTop: 10 }}>
                                    <Row>
                                        <Col md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {highFile ?
                                                <>
                                                    <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer' }}>
                                                        <div className="position-relative" style={{ width: 300, height: 300 }}>
                                                            <img
                                                                src={highFile}
                                                                alt=""
                                                                style={{ width: '100%', height: 'auto' }}
                                                            />
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                onClick={(e) => setHighFile(null)}
                                                            >
                                                                <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="position-relative image_placeholder" style={{ width: 300, height: 300 }}>
                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                        onClick={(e) => imageInput2.current.click(e)}
                                                    >
                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                    </div>
                                                </div>
                                            }
                                            <input type="file" id="file"
                                                ref={imageInput2}
                                                onChange={(e) => onChangeFile(e, 'high')}
                                                style={{ display: "none" }} />
                                        </Col>
                                        <Col md={8}>
                                            <div className="form-group mb-4">
                                                {/* How it works */}
                                                <label>Description*</label>
                                                <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => onEditorStateChange(e, 'high')}
                                                    editorState={highDescContent}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <h4 className="card-title mb-4 mt-4">Itinerary</h4>
                            {itin?.map((item, ind) => (
                                <div style={{ border: '1px dashed ' + Utils.themeColor, padding: 10, marginTop: 10 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h4 className="card-title mb-4">Step {ind + 1}</h4>
                                        {ind > 0 &&
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: Utils.themeColor, padding: '5px 9px', cursor: 'pointer' }}
                                                onClick={() => handleRemoveIntin(ind)}
                                            >
                                                <i className='mdi mdi-delete' style={{ fontSize: 20, color: 'white' }} />
                                            </div>
                                        }
                                    </div>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"title" + ind}
                                                    label="Title*"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                    value={item?.title}
                                                    onChange={(e) => onChangeItinTitle(ind, e.target.value)}
                                                >
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <div className="form-group mb-4">
                                                    {/* How it works */}
                                                    <label>Description*</label>
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => onEditorStateChangeItin(e, ind)}
                                                        editorState={item?.descContent}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <div className="d-flex mt-2">
                                <div className="text-center" style={{ marginLeft: 'auto' }}>
                                    <Button color="primary" onClick={() => handleDupliItin()} style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 20 }}>
                                        Duplicate
                                    </Button>
                                    <Button color="primary" onClick={() => handleMoreitin()} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                        + Add More Step
                                    </Button>
                                </div>
                            </div>
                            <h5 className="text-black font-size-20">Add Pdf</h5>
                            <div>
                                {pdf ?
                                    <div style={{ position: 'relative', width: 200 }}>
                                        <Link to={{ pathname: typeof pdf == 'object' ? URL.createObjectURL(pdf) : pdf }} target="_blank">
                                            <img src={require('../../assets/images/pdf.png')} style={{ height: 200 }} />
                                        </Link>
                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            onClick={(e) => setPdf(null)}
                                        >
                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                        </div>
                                    </div>
                                    :
                                    <Col lg={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                className="form-control"
                                                type="file"
                                                accept=".pdf"
                                                name="pdf"
                                                onChange={e => setPdf(e.target.files[0])}
                                            />
                                        </div>
                                    </Col>
                                }
                            </div>
                            <h4 className="card-title mb-4 mt-4 d-flex justify-content-between align-items-center">
                                <span>Map Screenshot</span><Button color="primary" onClick={() => setIsMapPointer(!isMapPointer)} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                    {mapImage ? 'Delete Map Image' : 'Add Map Image'}
                                </Button>
                            </h4>
                            {isMapPointer &&
                                <>
                                    {mapImage ?
                                        <>
                                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer' }}>
                                                <div className="position-relative" style={{ width: 200, height: 'auto' }}>
                                                    <img
                                                        src={mapImage}
                                                        alt=""
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                        onClick={(e) => setMapImage(null)}
                                                    >
                                                        <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="position-relative image_placeholder" style={{ width: 200, height: 200, cursor: 'pointer' }} onClick={(e) => mapInput.current.click(e)}>
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -16, top: -16, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            // onClick={(e) => bannerInput.current.click(e)}
                                            >
                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                        </div>
                                    }
                                    <input type="file" id="map"
                                        ref={mapInput}
                                        onChange={(e) => onChangeFile(e, 'map')}
                                        style={{ display: "none" }} />
                                </>
                            }
                            <h5 className="text-black font-size-20 mt-4">On Page</h5>
                            <Row className="mt-4">
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageTitle"
                                            label="Page Title"
                                            className="form-control"
                                            placeholder="Enter page title"
                                            type="text"
                                            required
                                            value={data?.pageTitle}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageKey"
                                            label="Page Keywords"
                                            className="form-control"
                                            placeholder="Enter page keyword (keyword1, keyword2, ...)"
                                            type="textarea"
                                            required
                                            value={data?.pageKey}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mb-3">
                                        <AvField
                                            name="pageDesc"
                                            label="Page Description"
                                            className="form-control"
                                            placeholder="Enter page description"
                                            type="textarea"
                                            required
                                            value={data?.pageDesc}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="text-center mt-4">
                                {data?._id ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update tour
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add tour
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

AddTour.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddTour)
)
