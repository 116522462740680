import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom";
import { profileSuccess, editProfile, getProfile } from '../../store/auth/profile/actions';

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import avatar1 from "../../assets/images/icon.png"
import Utils from '../../pages/Utility';
const Sidebar = props => {

  const { user } = props;

  useEffect(() => {
      getProfile();
  }, []);

  const getProfile = async () => {
    const user = JSON.parse(await localStorage.getItem("authUser"));
    // console.log('user from storage', user?.user);
    if (user)
      props.getProfile(user);
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img src={user?.profile_picture ? user.profile_picture : avatar1} alt="" className="avatar-md mx-auto rounded-circle" style={{objectFit:'cover', border:`2px dashed ${Utils.themeColor}`}} />
            </div>
            <div className="mt-3">
              <Link to="#" className="text-dark fw-medium font-size-16">{user?.name}</Link><br></br>
              {user?.role=='Admin' ?
                <p className="text-body mt-1 mb-0 font-size-13">(Admin)</p>
                :
                <p className="text-body mt-1 mb-0 font-size-13">{user?.company ? user?.company?.title : ''} ({user?.role})</p>
              }
            </div>
          </div>
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent user={props?.user} /> : <SidebarContent user={props?.user}/>}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
  profileSuccess: PropTypes.func,
  editProfile: PropTypes.func,
  getProfile: PropTypes.func
}

const mapStatetoProps = state => {
  // console.log('props on slidebar', state);
  return {
    layout: state.Layout,
    user: state.Profile?.user
  }
}
export default connect(
  mapStatetoProps,
  { profileSuccess, editProfile, getProfile }
)(withRouter(withTranslation()(Sidebar)))