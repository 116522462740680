import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Modal,
  ModalBody
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
// actions
import Loader from "../../components/Loader";
import { toast } from 'react-toastify';
import { get, post, put, upload } from '../../helpers/api_helper';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import DeleteModal from './DeleteModal';

const Home = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [cropperModal, setCropperModal] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(null);
  const [needSave, setNeedSave] = useState(false);
  const [title, setTitle] = useState("Aahilya Holidays");
  const [subTitle, setSubtitle] = useState("Creative Cultural Tours");
  const [textColor, setTextColor] = useState("#000000");
  const bannerRef = useRef();
  const cropper = useRef();
  const [deleteModal, setDeleteModal] = useState(false);
  // console.log('props on company', props);

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = () => {
    get('/home')
      .then(res => {
        console.log('response of get banner', res);
        if (res?.statusCode == 200) {
          if (res?.data) {
            setData(res?.data);
            setFile(res?.data?.image);
            setTitle(res?.data?.title);
            setSubtitle(res?.data?.subTitle);
            setTextColor(res?.data?.color);
          }
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        console.log('error while getting home banner', err);
        toast.error('Something went wrong!');
      })
  }

  const onChangeBanner = (file) => {
    console.log('changing file', file);
    if (file) {
      setFile(URL.createObjectURL(file));
      setCropperModal(true);
    }
  }

  const cropImage = async () => {
    setLoading(true);
    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
    console.log('values 2', fileObj);
    const image2 = await resizeFile(fileObj);
    console.log('values 2 after resize', image2);
    setCropperModal(false);
    setImageChanged(image2);
    setNeedSave(true);
    setLoading(false);
  }

  const handleTextChange = (to, val) => {
    if (to == 'title') {
      setTitle(val);
      setNeedSave(true);
    }
    else {
      setSubtitle(val);
      setNeedSave(true);
    }
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1750,
        1000,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleSubmit = async () => {
    let body = {
      image: 'https://aahilyaholidays.com/images/about-India/banner_8.jpg',
      title,
      subTitle,
      color: textColor,
      token: user?.token
    }
    if (imageChanged) {
      const form2 = new FormData();
      form2.append("image", imageChanged);
      const uploadedBanner = await upload("/home/image_upload", form2)
      console.log('response from image upload', uploadedBanner);
      if (uploadedBanner?.statusCode == 200)
        body.image = uploadedBanner?.data;
    }
    if (data) {
      body = { ...body, homeId: data?._id }
      put('/home/update', body)
        .then(res => {
          console.log('response of update banner', res);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            setNeedSave(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log('error while getting home banner', err);
          toast.error('Something went wrong!');
        })
    } else {
      post('/home/add', body)
        .then(res => {
          console.log('response of add banner', res);
          if (res?.statusCode == 200) {
            setNeedSave(false);
            toast.success(res?.message);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log('error while getting home banner', err);
          toast.error('Something went wrong!');
        })
    }
  }

  const onDelete = () => {
    let body = {
      image: '',
      token: user?.token
    }
    if (data) {
      body = { ...body, homeId: data?._id }
      put('/home/update', body)
        .then(res => {
          console.log('response of update banner', res);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getBanner();
            setNeedSave(false);
            setDeleteModal(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log('error while getting home banner', err);
          toast.error('Something went wrong!');
        })
    } else {
      setDeleteModal(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={onDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Home Banner" />
        <Card>
          <CardBody>
            <Modal className="modal_form_banner" isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }}>
              <ModalBody className="py-3 px-5">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                  <h5 className="text-black font-size-20">Crop Banner</h5>
                  <div style={{}}>
                    <Button style={{ backgroundColor: Utils.themeColor, marginRight: 10, border: 'none' }} onClick={() => { setFile(data?.image); setCropperModal(false) }}>Cancel</Button>
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={cropImage}>Submit</Button>
                  </div>
                </div>
                <Cropper
                  style={{ height: 'auto', width: 1300 }}
                  aspectRatio={1750 / 1000}
                  preview=".img-preview"
                  guides={true}
                  src={file}
                  ref={cropper}
                />
              </ModalBody>
            </Modal>
            <Row>
              <Col lg={11} md={11}></Col>
              <Col lg={1} md={1}>
                {needSave &&
                  <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => handleSubmit()}>Save</Button>
                }
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12}>
                <Label></Label>
                <div style={{ border: '1px dashed ' + Utils?.themeColor, height: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {file ?
                    <>
                      <img src={file} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      <div style={{ position: 'absolute', top: 30, right: 30 }}>
                        <div style={{ borderRadius: 50, backgroundColor: '#35b5ac', width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => bannerRef?.current?.click()}
                        >
                          <i className='mdi mdi-camera' style={{ color: '#fff', fontSize: 30 }} />
                        </div>
                        <div style={{ marginTop: 10, borderRadius: 50, backgroundColor: '#35b5ac', width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className='mdi mdi-delete' style={{ color: '#fff', fontSize: 30 }} />
                        </div>
                      </div>
                      <div style={{ position: 'absolute', right: '50%', top: '50%', transform: 'translate(50%, -50%)' }}>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', position: 'relative' }}>
                          <input type='text'
                            className='input_text'
                            style={{ color: textColor, textShadow: '0.6px 1.2px #000' }}
                            value={title}
                            onChange={(e) => handleTextChange('title', e.target.value)}
                          />
                          <input id='color_picker' type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} style={{ visibility: 'hidden' }} />
                          <label htmlFor='color_picker' className='color_p'>
                            <i className='mdi mdi-format-color-fill'></i>
                          </label>
                          <input
                            type='text'
                            style={{ color: textColor, fontSize: 20, textAlign: 'right', backgroundColor: 'transparent', minWidth: 100, marginTop: 10, textShadow: '1px 1px #000' }}
                            className='input_text'
                            value={subTitle}
                            onChange={(e) => handleTextChange('subtitle', e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                    :
                    <div>
                      <div style={{ position: 'absolute', right: '50%', top: '50%', transform: 'translate(50%, -50%)' }}>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', position: 'relative' }}>
                          <input type='text'
                            className='input_text'
                            style={{ color: textColor, textShadow: '0.6px 1.2px #000' }}
                            value={title}
                            onChange={(e) => handleTextChange('title', e.target.value)}
                          />
                          <input id='color_picker' type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} style={{ visibility: 'hidden' }} />
                          <label htmlFor='color_picker' className='color_p'>
                            <i className='mdi mdi-format-color-fill'></i>
                          </label>
                          <input
                            type='text'
                            style={{ color: textColor, fontSize: 20, textAlign: 'right', backgroundColor: 'transparent', minWidth: 100, marginTop: 10, textShadow: '1px 1px #000' }}
                            className='input_text'
                            value={subTitle}
                            onChange={(e) => handleTextChange('subtitle', e.target.value)}
                          />
                        </div>
                      </div>
                      <Button style={{ position: 'absolute', top: 30, right: 30, backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => bannerRef?.current?.click()}>Add Banner</Button>
                    </div>
                  }
                  <input type="file" onChange={(e) => onChangeBanner(e.target.files[0])} style={{ display: 'none' }} ref={bannerRef} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Home)
)
