import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'
import avatar from "../../assets/images/companies/img-4.png";
import AddCard from '../Forms/AddTour';
import Table from './Table';
import Loader from "../../components/Loader";
import CompSingle from './compSingle';
import MetaTags from 'react-meta-tags';


const Tour = props => {
  const { user, company } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [singleId, setSingleId] = useState(null);
  // console.log('props on company', props);

  useEffect(() => {
    if (props.location) {
      console.log('path on opportunities', props.location.pathname.split("/"));
      const path = props.location.pathname;
      if (path.split("/").length > 1) {
        const id = path.split("/")[2];
        if (id) {
          setSingleId(id);
          setIsAddForm(false);
          setIsTable(false);
        }
      } else {
        setIsTable(true);
      }
    }
  }, [props]);

  useEffect(() => {
    if (isAddForm)
      setIsTable(false);
    else {
      setCurrentData(null);
      setIsTable(true);
    }
  }, [isAddForm]);

  return (
    <React.Fragment>
      <MetaTags>
        <meta name="description" content="Helmet application" />
      </MetaTags>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Tours" />
        {isAddForm &&
          <AddCard
            role={"Tour"}
            user={user}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
            getProfile={props?.getProfile}
          />
        }
        {isTable &&
          <Card>
            <CardBody>
              <Table
                role={"Tours"}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentData}
                setCurrentData={setCurrentData}
                history={props.history}
              />
            </CardBody>
          </Card>
        }
        {!isAddForm && !isTable &&
          <CompSingle
            singleId={singleId}
            user={user}
            setLoading={setLoading}
            setIsAddForm={setIsAddForm}
            history={props.history}
            setCurrentData={setCurrentData}
          />
        }

      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Tour)
)
