import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
// import './Card.scss';

const Booking = (props) => {
  const { user, role, currentData, from, setCurrentData, path } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userType, setUserType] = useState('users');
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    // console.log('props on user table', props)
    if (user) {
      getData();
    }
  }, [user, userType]);

  const getData = () => {
    let url = "/user/list?type=" + path;
    get(url, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
          setTempData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Name',
        selector: row => row?.name,
      },
      {
        name: 'Email',
        selector: row => row?.email,
      },
      {
        name: 'Phone',
        selector: row => row?.phone,
      },
      {
        name: 'Tour',
        selector: row => row?.tour[0]?.name,
      },
      {
        name: 'Created At',
        selector: row => row.createdAt,
      },
      // {
      //   cell: (row) => <>
      //     {from != 'Dashboard' &&
      //       <>
      //         <Button onClick={() => handleDeleteProj(row, 'query')}
      //           title={"Ban"}
      //           style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-cancel" style={{ fontSize: 20 }}></span></Button>
      //       </>
      //     }
      //   </>,
      //   name: 'Action',
      //   ignoreRowClick: true,
      // }
    ])
  }, [data, path]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj, type) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete', type: type });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      userId: currentData?._id,
      token: user?.token
    }
    let url = "/user/delete";
    if (currentData?.type == 'query') {
      body = { ...body, queryId: currentData?._id };
    }
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleSearch = (str) => {
    setSearchStr(str);
    let temp = Object.assign([], tempData);
    let dataToSet = [];
    temp.forEach(item => {
      if (
        item?.name.toLowerCase().includes(str.toLowerCase()) ||
        item?.email.toLowerCase().includes(str.toLowerCase()) ||
        item?.phone.toLowerCase().includes(str.toLowerCase()) ||
        item?.tour[0]?.name.toLowerCase().includes(str.toLowerCase()))
        dataToSet.push(item);
    });
    console.log('item searched', dataToSet);
    setData(dataToSet);
  }

  const ExpandableRowComponent = ({ data, someTitleProp }) => {
    return (
      <div style={{ backgroundColor: '#f1f1f1', padding: 10 }}>
        <Row style={{ padding: 10 }}>
          <Col lg={3} style={{marginTop:10}}>
            <span>Insurance: </span>
            <span>{data?.insurancePolicyNo}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Date Of Birth: </span>
            <span>{data?.dob}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Covid Jab: </span>
            <span>{data?.covid ? "Yes" : "No"}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Preferred Correspondence: </span>
            <span>{data?.isCorrEmail ? "E-Mail" : ""} {data?.isCorrTele ? "Telephone" : ""} {data?.isCorrPost ? "Post" : ""}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Single Room: </span>
            <span>{data?.isSingleRoom ? "Yes" : "No"}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Book Flights: </span>
            <span>{data?.isFlight ? "Yes" : "No"}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Phone (Home): </span>
            <span>{data?.eHPhone}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Dietary: </span>
            <span>{data?.dietary}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Address: </span>
            <span>{data?.address}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Medications: </span>
            <span>{data?.medication}</span>
          </Col>
        </Row>
        <span style={{textDecoration:'underline'}}>Emergency Contact:</span>
        <Row style={{ padding: 10 }}>
          <Col lg={3} style={{marginTop:10}}>
            <span>Name: </span>
            <span>{data?.eName}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Email: </span>
            <span>{data?.eEmail}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Phone: </span>
            <span>{data?.ePhone}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Phone (Home): </span>
            <span>{data?.eHPhone}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Relation: </span>
            <span>{data?.eRelation}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Address: </span>
            <span>{data?.eAddress}</span>
          </Col>
          <Col lg={3} style={{marginTop:10}}>
            <span>Contact No: </span>
            <span>{data?.eContactNo}</span>
          </Col>
        </Row>
      </div>
    )
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                {from != 'Dashboard' &&
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {role} those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                      </CardSubtitle>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      <AvForm>
                        <AvField
                          name="search"
                          label="Search"
                          type="text"
                          value={searchStr}
                          onChange={(e) => handleSearch(e.target.value)}
                        >
                        </AvField>
                      </AvForm>
                    </div>
                  </div>
                }
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  expandableRows
                  expandableRowsComponent={ExpandableRowComponent}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Booking;