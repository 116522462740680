import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile";
import Users from "../pages/Users";

//page
import Notification from "../pages/Notification";
import Experience from '../pages/Experience';
import Destination from "../pages/Destination";
import Gallery from "../pages/Gallery";
import Store from "../pages/Store";
import Blog from "../pages/Blog";
import Home from "../pages/Home";
import Affiliation from "../pages/Affiliation";
import Tour from '../pages/Tour';
import Testimonial from "../pages/Testimonial";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: '/dashboard2', component: Dashboard2 },
  { path: "/home", component: Home },
  { path: "/experience", component: Experience },
  { path: "/destination", component: Destination },
  // { path: "/destination/:id", component: Destination },
  { path: "/gallery", component: Gallery },
  { path: "/store", component: Store },
  { path: '/blog', component: Blog },
  { path: '/blog/add', component: Blog },
  { path: '/affiliation', component: Affiliation },
  { path: '/tour', component: Tour },
  { path: '/testimonial', component: Testimonial },

  //Notification
  { path: "/notification", component: Notification },
  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/users/booking", component: Users },
  { path: "/users/subscriber", component: Users },
  { path: "/users/enquiry", component: Users },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }