import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    CardTitle,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'

const AddBlog = props => {
    const { user, data, setLoading, close, setCurrentData } = props;
    const [file, setFile] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [keywords, setKeywords] = useState([]);
    const imageInput = useRef();
    const cropper = useRef();

    useEffect(() => {
        console.log('keywords', data);
        if (data) {
            if (data?.keywords?.length)
                setKeywords(data?.keywords);
            if (data?.banner) {
                setFile(data?.banner);
                setImageChanged(false);
            }
            if (data?.desc) {
                const contentBlock = htmlToDraft(data?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.desc);
                }
            }
        }
    }, [data]);

    const handleValidSubmit = async (e, values, type) => {
        // console.log('values', values, file);
        setLoading(true);
        if (data) {
            let body = {
                ...values,
                blogId: data?._id,
                desc: desc,
                keywords: keywords,
                token: user?.token
            }
            if (imageChanged) {
                console.log('updating month changed', typeof file);
                const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
                const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
                const image = await resizeFile(obj);
                const form = new FormData();
                form.append("image", image);
                let res = await upload("/blog/image_upload", form)
                if (res?.statusCode == 200)
                    body.banner = res?.data;
            }
            put("/blog/update", body)
                .then(json => {
                    console.log('response from add month', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        toast.success(json?.message);
                        // getMetas();
                        setFile(null);
                        close(false);
                        // setdata(null);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while uploading image', error);
                    toast.error(error);
                });
        } else {
            const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
            urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
                .then(async (obj) => {
                    console.log('values', obj);
                    const image = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", image);
                    upload("/blog/image_upload", form)
                        .then(res => {
                            console.log('response from image upload', res);
                            if (res?.statusCode == 200) {
                                const body = {
                                    ...values,
                                    banner: res?.data,
                                    desc: desc,
                                    token: user?.token
                                }
                                post("/blog/add", body)
                                    .then(json => {
                                        console.log('response from add blog', json);
                                        setLoading(false);
                                        if (json.statusCode == 200) {
                                            toast.success(json?.message);
                                            // getMetas();
                                            setFile(null);
                                            close(false);
                                        } else {
                                            toast.error(json?.error);
                                        }
                                    })
                                    .catch(error => {
                                        setLoading(false);
                                        console.log('error while uploading image', error);
                                        toast.error(error);
                                    })
                            } else {
                                setLoading(false);
                                toast.error(res?.error);
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            console.log('error while uploading image', error);
                            toast.error(error);
                        })
                });
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "JPEG",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const onChangeFile = (e) => {
        console.log('getting event on input img', e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageChanged(true);
    }

    const onEditorStateChange = (editorState, to) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const addChip = (value) => {
        // const temp = Object.assign([], keywords);
        // temp.push(value);
        setKeywords(value);
    };

    const removeChip = (index) => {
        const temp = Object.assign([], keywords);
        temp.splice(index, 1);
        setKeywords(temp);
    };

    return (
        <React.Fragment>
            <div>
                <Card>
                    <CardBody>
                        <h5 className="text-black font-size-20">{data ? 'Update Blog!' : 'Add New Blog!'}</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v, 'bank')
                                }}
                            >
                                <div className="mb-3">
                                    <AvField
                                        name="name"
                                        label="Name"
                                        className="form-control"
                                        placeholder="Enter name"
                                        type="text"
                                        required
                                        value={data?.name}
                                    />
                                </div>
                                <div className="mb-3 mt-2">
                                    {file ?
                                        <>
                                            <CardTitle >Image</CardTitle>
                                            <div style={{ flexDirection: 'row', position: 'relative', width: 400, cursor: 'pointer', marginTop: 20 }}>
                                                {data?.banner ?
                                                    <img src={file} style={{ width: 400, height: 'auto' }} />
                                                    :
                                                    <Cropper
                                                        style={{ height: 'auto', width: 400 }}
                                                        // aspectRatio={16 / 5}
                                                        preview=".img-preview"
                                                        guides={true}
                                                        src={file}
                                                        ref={cropper}
                                                    />
                                                }
                                                <i className="mdi mdi-close" style={{
                                                    color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                                    position: 'absolute', top: -13, right: -13, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                                }}
                                                    onClick={() => { setFile(null); setCurrentData({ ...data, banner: undefined }) }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <AvField
                                            name="fileInput"
                                            label="Image"
                                            className="form-control"
                                            placeholder="upload bank logo"
                                            type="file"
                                            onChange={e => onChangeFile(e)}
                                            required
                                            multiple={false}
                                            ref={imageInput}
                                            accept="image/png, image/jpeg"
                                        // value={data?.logo}
                                        />
                                    }
                                </div>
                                <Label>Keywords</Label>
                                <TagsInput
                                    value={keywords}
                                    onChange={(value) => addChip(value)}
                                    inputProps={{ placeholder: "Type a keyword and then press enter", style: { minWidth: '90%' } }}
                                />
                                <Row>
                                    <div className="form-group mb-4" style={{ width: '100%' }}>
                                        <label>Description*</label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName test"
                                            onEditorStateChange={(e) => onEditorStateChange(e)}
                                            editorState={descContent}
                                        />
                                    </div>
                                </Row>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                onClick={() => { close(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            >
                                                {data ? 'Update' : 'Add'}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>

                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddBlog)
)
