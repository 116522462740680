import {
  GET_NOTI_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  notis: [],
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTI_SUCCESS:
      return {
        ...state,
        notis: action.payload,
      }
    default:
      return state
  }
}

export default Calendar
