import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { withRouter, Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import { getNotiSuccess } from "../../../store/notification/actions";

//Import images
import avatar3 from "../../../assets/images/logo-sm-dark.png"

//i18n
import { withTranslation } from "react-i18next";

import { get } from "../../../helpers/api_helper";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { user } = props;
  const [data, setData] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);

  const { getNotiSuccess } = props;

  useEffect(() => {
    if (user?.companyId)
      getNoti();
  }, [user]);

  useEffect(()=>{
    if(props.notis.length>0)
    setUnseenCount(props?.notis[0].count)
  },[props?.notis])

  const getNoti = () => {
    // console.log('calling get user list get method');
    get("/notification/list", { token: user?.token })
      .then(json => {
        // console.log('response from get notification list', json);
        if (json?.statusCode == 200){
          setData(json?.data);
          // setUnseenCount(json?.unseen);
          getNotiSuccess([{count:json?.unseen}])
        }
      })
      .catch(error => {
        console.log('error while getting user list', error);
      })
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {unseenCount > 0 &&
            <span className="badge rounded-pill bg-danger ">{unseenCount}</span>
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{" "}{props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  <Link to="/notification">View All</Link>
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {data?.map((item, index) => {
              if (index < 6)
                return (
                  <div key={item?._id}>
                  <Link to="/notification" className="text-reset notification-item">
                    <div className="d-flex align-items-start">
                      <img
                        src={item?.image ? item?.image : avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1">{item?.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {props.t(item?.body) + "."}
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />
                            {props.t(item?.createdAt)}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </div>
                )
            })}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notification"
            ><i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

NotificationDropdown.propTypes = {
  getNotiSuccess: PropTypes.func,
}

export default withTranslation()(withRouter(
  connect(mapStateToProps, { getNotiSuccess })(NotificationDropdown)
))

NotificationDropdown.propTypes = {
  t: PropTypes.any
}