import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
    Card,
    CardBody,
} from "reactstrap"
import { connect } from "react-redux";

const RevenueChart = (props) => {
    const { dealers } = props;

    console.log('props on revenue chart', dealers);

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            stacked: !0,
            toolbar: {
                show: !1
            },
            zoom: {
                enabled: !0
            }
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: '20%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: !1
        },
        xaxis: {
            categories: [],
        },
        colors: ['#ffa500', '#00ff00', '#ff0000'],
        fill: {
            opacity: 1
        }
    });

    useEffect(() => {
        if(dealers?.length>0) {
            modifyData();
        }
    }, [dealers]);


    const modifyData = () => {
        let cat = [], openLead=[], closeLead=[], rejectedLead=[];
        dealers.map((item, index)=>{
            cat.push(item?.name);
            openLead.push(0);
            closeLead.push(0);
            rejectedLead.push(0);
            // console.log('dealer names', item?.openLead);
            item?.lead.map((lead)=>{
                if(lead?._id?.status=='In Process')
                    openLead[index] = lead?.count;
                else if(lead?._id?.status=='Closed')
                    closeLead[index]=lead?.count;
                else if(lead?._id?.status=='Rejected')
                    rejectedLead[index] = lead?.count;
            })
        });
        setOptions({...options, xaxis:{categories:cat}});
        setSeries([{
            name: 'In Process',
            data: openLead
        }, {
            name: 'Closed',
            data: closeLead
        }, {
            name: 'Rejected',
            data: rejectedLead
        }]);
        // console.log(options);
        // console.log(openLead, closeLead, rejectedLead);
    }

    const width = props.layoutWidth === "boxed" ? 1000 : 1080;

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Channel Partner</h4>
                    <div id="revenuechart">
                        <ReactApexChart
                            options={options}
                            series={series}
                            width={width}
                            height={260}
                            type="bar"
                            className="apex-charts"
                        />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return { ...state.Layout }
}


export default connect(mapStateToProps, null)(RevenueChart)
//export default RevenueChart;