import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import avatar from '../../assets/images/bg-effect.png';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/tour/list/admin", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'banner'} src={row?.images?.length > 0 ? row?.images[0] : avatar} style={{ width: 100, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: '130px'
      },
      {
        name: 'Title',
        selector: row => row.name,
      },
      {
        name: 'Experience',
        selector: row => row?.experience[0]?.name,
        maxWidth: '150px'
      },
      // {
      //   name: 'Artist',
      //   selector: row => row?.artistName,
      // },
      // {
      //   name: 'Category',
      //   selector: row => row.categories[0]?.name,
      // },
      // {
      //   name: 'Created At',
      //   selector: row => row.createdAt,
      // },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '108px'
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/destination/` + row?._id)}
            title={"View"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          {from != 'Dashboard' &&
            <>
              <Button onClick={() => handleUpdateProj({ ...row, _id: undefined })}
                title={"Duplicate"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
      },
    ])
  }, [data]);

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      destinationId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/destination/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("destination"))
            toast.success(json?.message, {
              toastId: "destination"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("destination"))
            toast.error(json?.error, {
              toastId: "destination"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      tourId: currentData?._id,
      token: user?.token
    }
    let url = "/tour/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      url = "/tour/delete"
      body = { ...body };
      post(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/tour/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                {from != 'Dashboard' &&
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {role} you have added before, All the <code> users</code> will also be able see and <code>check</code> on these.
                      </CardSubtitle>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    </div>
                  </div>
                }
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={30}
                          paginationRowsPerPageOptions={[30, 60, 90, 120]}
                          conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                          },
                          ]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
