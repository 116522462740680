import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import avatar from '../../assets/images/bg-effect.png';
// import './destination.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/destination/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      destinationId: id,
      isPopular: val=="true" ? false : true,
      token: user?.token
    }
    put("/destination/update", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'banner'} src={row?.images?.length>0 ? row?.images[0] : avatar} style={{ width: 150, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
      },
      {
        name: 'Title',
        selector: row => row.name,
      },
      {
        name: 'Tags',
        selector: row => row?.tag?.length,
      },
      {
        name: 'FAQ',
        selector: row => row?.faq?.length,
      },
      // {
      //   name: 'Category',
      //   selector: row => row.categories[0]?.name,
      // },
      // {
      //   name: 'Created At',
      //   selector: row => row.createdAt,
      // },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/destination/` + row?._id)}
            title={"View"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          {from != 'Dashboard' &&
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      destinationId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/destination/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("destination"))
            toast.success(json?.message, {
              toastId: "destination"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("destination"))
            toast.error(json?.error, {
              toastId: "destination"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      destinationId: currentData?._id,
      token: user?.token
    }
    let url = "/destination/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      url = "/destination/delete"
      body = { ...body };
      post(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                {from != 'Dashboard' &&
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {role} you have added before, All the <code> users</code> will also be able see and <code>check</code> on these.
                      </CardSubtitle>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    </div>
                  </div>
                }
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  conditionalRowStyles={[{
                    when: row => row?.style,
                    style: row => ({ width: row?.style?.width }),
                  },
                  ]}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        width: 0
                      },
                    },
                    cells: {
                      style: {
                        width: 0
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
